import React, { Component, Fragment } from "react"

import { withAuth0 } from "@auth0/auth0-react"
import Alert from "react-bootstrap/Alert"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Table from "react-bootstrap/Table"
import { FormattedMessage } from "react-intl"
import { injectIntl } from "react-intl"
import { useIntl } from "react-intl"
import ReactMarkdown from "react-markdown"

import Error from "./error"
import Loading from "./loading"

class WalletBalances extends Component {
  constructor(props) {
    super(props)
    this.getRate = this.getRate.bind(this)
    this.refreshBalances = this.refreshBalances.bind(this)
    this.refreshRates = this.refreshRates.bind(this)
    this.state = { loaded: false, error: null, balances: null, rates: null }
  }

  componentDidMount() {
    this.timer1 = setInterval(this.refreshBalances, 60 * 1000)
    this.timer2 = setInterval(this.refreshRates, 60 * 1000)
    this.refreshBalances()
    this.refreshRates()
  }

  componentWillUnmount() {
    clearTimeout(this.timer1)
    clearTimeout(this.timer2)
  }

  getRate(currencyFrom, currencyTo) {
    var rates = this.state.rates
    for (var i = 0; i < rates.length; i++) {
      var rate = rates[i]
      if (rate.currencyFrom == currencyFrom && rate.currencyTo == currencyTo) {
        return rate.value
      }
    }
    return null
  }

  refreshBalances() {
    this.props.auth0
      .getAccessTokenSilently({
        audience: process.env.GATSBY_AUTH0_AUDIENCE,
        scope: process.env.GATSBY_AUTH0_SCOPE,
      })
      .then(accessToken => {
        const url = new URL("/wallet/balances", process.env.GATSBY_API_URL)
        const params = {}
        Object.keys(params).forEach(key =>
          url.searchParams.append(key, params[key])
        )
        fetch(url, {
          method: "GET",
          headers: { Authorization: `Bearer ${accessToken}` },
        })
          .then(response => {
            if (response.ok) {
              response.json().then(json => {
                this.setState({ loaded: true, balances: json })
              })
            } else {
              response.text().then(text => {
                this.setState({ loaded: true, error: text })
              })
            }
          })
          .catch(error => {
            this.setState({
              loaded: true,
              error: error,
            })
          })
      })
  }

  refreshRates() {
    const url = new URL("/exchange_rates/latest", process.env.GATSBY_API_URL)
    fetch(url, { method: "GET" }).then(response => {
      if (response.ok) {
        response.json().then(json => {
          this.setState({ isLoaded: true, rates: json })
        })
      } else {
        response.text().then(text => {
          this.setState({ isLoaded: true, error: text })
        })
      }
    })
  }

  render() {
    const { intl } = this.props
    const { loaded, error, balances, rates } = this.state
    if (!error && (!balances || !rates)) {
      return <Loading />
    } else if (error) {
      return <Error error={error} />
    } else {
      balances.map(balance => {
        balance.eur = balance.amount * this.getRate(balance.currency, "EUR")
      })

      return (
        <Row>
          <Col className="col-auto">
            <List items={balances} />
          </Col>
          <Col className="col-auto">
            <Alert variant="secondary" style={{ width: "18rem" }}>
              <ReactMarkdown
                children={intl.formatMessage({ id: "Wallet.Balances" })}
              />
            </Alert>
          </Col>
        </Row>
      )
    }
  }
}

function List(props) {
  const intl = useIntl()
  return (
    <Table striped bordered hover responsive>
      <thead className="thead-dark">
        <tr>
          <th>{intl.formatMessage({ id: "Currency" })}</th>
          <th>{intl.formatMessage({ id: "Balance" })}</th>
          <th>{intl.formatMessage({ id: "EUR" })}</th>
        </tr>
      </thead>
      <tbody>
        {props.items.map(item => (
          <Item key={item.id} value={item} />
        ))}
      </tbody>
    </Table>
  )
}

function Item(props) {
  const value = props.value
  return (
    <tr>
      <td className="text-center">{value.currency}</td>
      <td className="text-right">{value.amount.toFixed(9)}</td>
      <td className="text-right">{value.eur.toFixed(2)}</td>
    </tr>
  )
}

export default withAuth0(injectIntl(WalletBalances))
